<template>
  <!--   <ion-page>-->
  <ion-content :scroll-events="true">
    <!--   <ion-grid fixed>
        <ion-row
          class="ion-justify-content-center ion-align-items-center ion-margin-top"
        >
          <ion-col
            class="ion-align-self-center ion-margin-top"
            size-md="6"
            size="12"
          > -->
    <ion-card>
      <ion-card-header class="ion-margin-top">
        <ion-card-title size="4" class="margen5px negrita">
          {{ !editing ? "Crear Lote" : "Editar Lote" }}
        </ion-card-title>
        <ion-row
          class="ion-margin-top ion-justify-content-left"
          v-if="!isAdmin"
        >
          <ion-label
            class="ion-align-self-center ion-margin-top label margen5px"
          >
            {{ "Productor: " }}
          </ion-label>
          <ion-text
            class="ion-align-self-center ion-margin-top ion-text-capitalize negrita"
          >
            {{ persona.nombre + " " + persona.apellido }}
          </ion-text>
        </ion-row>
      </ion-card-header>
      <ion-card-content>
        <v-form @submit="submit">
          <ion-button
            fill="outline"
            style="white-space:normal"
            color="medium"
            v-if="!editing && !isMapPath"
            @click="goToMapa"
          >
            {{ "Buscar coordenadas en Mapa" }}
          </ion-button>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="floating">Latitud</ion-label>
                <ion-input
                  v-model="coordenadas.latitud"
                  autocapitalize="off"
                  type="text"
                  inputmode="text"
                  autocomplete="off"
                  placeholder="Grados decimales"
                ></ion-input>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-item>
                <ion-label position="floating">Longitud</ion-label>
                <ion-input
                  v-model="coordenadas.longitud"
                  autocapitalize="off"
                  type="text"
                  inputmode="text"
                  autocomplete="off"
                  placeholder="Grados decimales"
                ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-item v-if="isAdmin" :disabled="isUserSameProd">
            <ion-label position="floating" class="ion-margin-top">
              Productor
            </ion-label>
            <ion-select
              class="ion-text-capitalize"
              :value="productor"
              @ionChange="productor = $event.target.value"
            >
              <ion-select-option
                v-for="p in productores"
                :value="p._id"
                :key="p._id"
                class="ion-text-capitalize"
              >
                {{ p.apellido + ", " + p.nombre }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-row class="ion-align-items-center">
            <ion-col>
              <ion-item :disabled="isAdmin && !productor">
                <ion-label
                  :position="establecimiento ? 'stacked' : 'floating'"
                  class="ion-margin-top"
                >
                  Establecimiento
                </ion-label>
                <ion-select
                  class="ion-text-capitalize"
                  :value="establecimiento"
                  :selected-text="
                    establecimiento && establecimiento.nombre
                      ? establecimiento.nombre
                      : undefined
                  "
                  @ionChange="establecimiento = $event.target.value"
                >
                  <ion-select-option
                    v-for="est in establecimientos"
                    :value="est"
                    :key="est._id"
                    class="ion-text-capitalize"
                  >
                    {{ est.nombre }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col size="auto">
              <ion-fab-button @click="modalEstablecimiento = true" size="small">
                <ion-icon :md="icons.add" :ios="icons.add"></ion-icon>
              </ion-fab-button>
            </ion-col>
          </ion-row>
          <ion-item>
            <ion-label position="floating">Denominación</ion-label>
            <ion-input
              v-model="nombre"
              autocapitalize="words"
              type="text"
              inputmode="text"
              autocomplete="off"
              required
            ></ion-input>
          </ion-item>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="floating">Superficie</ion-label>
                <ion-input
                  v-model="hectareas"
                  autocapitalize="off"
                  type="text"
                  inputmode="text"
                  autocomplete="off"
                  required
                  placeholder="Hectáreas"
                ></ion-input>
              </ion-item>
            </ion-col>
            <ion-col class="ion-align-self-center ion-margin-top">
              <h2>
                Ha
              </h2>
            </ion-col>
          </ion-row>

          <ion-item
            class="ion-margin-top"
            :disabled="
              establecimiento &&
                establecimiento.regional &&
                establecimiento.regional.nombre
            "
          >
            <ion-label position="floating" class="ion-margin-top">
              Regional
            </ion-label>
            <ion-label
              v-if="!regional"
              position="floating"
              style="font-size: 12px"
            >
              (Opcional)
            </ion-label>
            <ion-select
              :value="regional"
              @ionChange="regional = $event.target.value"
            >
              <ion-select-option
                v-for="reg in regionales"
                :value="reg._id"
                :key="reg._id"
              >
                {{ reg.nombre }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Localidad</ion-label>
            <ion-input
              v-model="localidad"
              autocapitalize="Words"
              type="text"
              inputmode="text"
              autocomplete="off"
              required
            ></ion-input>
          </ion-item>
          <ion-item class="ion-margin-top">
            <ion-label position="floating" class="ion-margin-top">
              Tenencia
            </ion-label>
            <ion-label
              v-if="!tenencia"
              position="floating"
              style="font-size: 12px"
            >
              (Opcional)
            </ion-label>
            <ion-select
              :value="tenencia"
              @ionChange="tenencia = $event.target.value"
            >
              <ion-select-option v-for="t in tenencias" :value="t" :key="t">
                {{ t }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label position="floating" class="ion-margin-top">
              Clase de Suelo
            </ion-label>
            <ion-label
              v-if="!claseSuelo"
              position="floating"
              style="font-size: 12px"
            >
              (Opcional)
            </ion-label>
            <ion-select
              :value="claseSuelo"
              @ionChange="claseSuelo = $event.target.value"
            >
              <ion-select-option
                v-for="cS in clasesSuelo"
                :value="cS"
                :key="cS"
              >
                {{ cS }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label position="floating" class="ion-margin-top">
              Calidad del Lote
            </ion-label>
            <ion-label
              v-if="!calidadLote"
              position="floating"
              style="font-size: 12px"
            >
              (Opcional)
            </ion-label>
            <ion-select
              :value="calidadLote"
              @ionChange="calidadLote = $event.target.value"
            >
              <ion-select-option
                v-for="cL in calidadesLote"
                :value="cL"
                :key="cL"
              >
                {{ cL }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item v-show="error">
            <ion-text color="danger">
              <h4>{{ error1 }}</h4>
            </ion-text>
          </ion-item>
          <ion-row class="ion-float-right">
            <ion-col>
              <ion-button
                fill="outline"
                class="ion-margin-top"
                color="medium"
                @click="cancel"
              >
                Cancelar
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button class="ion-float-right ion-margin-top" type="submit">
                Aceptar
              </ion-button>
            </ion-col>
          </ion-row>
        </v-form>
      </ion-card-content>
    </ion-card>
    <!--           </ion-col>
        </ion-row>
      </ion-grid>-->
    <ion-modal
      id="modal"
      :is-open="modalEstablecimiento"
      @didDismiss="modalEstablecimiento = false"
      :backdropDismiss="false"
    >
      <EstablecimientoForm
        :error="error"
        :persona="productor"
        @cancel="modalEstablecimiento = false"
        @submit="submitEstablecimiento"
        class="modal-wrapper"
      />
    </ion-modal>
  </ion-content>
  <!--</ion-page> -->
</template>

<script>
import * as V from "vee-validate/dist/vee-validate";
import isLatLong from "validator/lib/isLatLong";
import {
  //IonPage,
  IonInput,
  IonLabel,
  IonText,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonCardHeader,
  IonItem,
  IonCard,
  IonCardTitle,
  IonFabButton,
  IonIcon,
  IonContent,
  IonRow,
  IonCardContent,
  IonCol,
  //IonGrid,
  alertController,
  IonButton
} from "@ionic/vue";
import { Regionales, Establecimientos, Persona } from "@/api";
import { mapState, mapGetters } from "vuex";
import { addOutline } from "ionicons/icons";
import EstablecimientoForm from "./EstablecimientoForm.vue";

export default {
  components: {
    //IonPage,
    IonInput,
    IonText,
    IonSelect,
    IonModal,
    IonCardHeader,
    IonSelectOption,
    IonLabel,
    IonItem,
    IonFabButton,
    IonIcon,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonContent,
    IonRow,
    IonCol,
    //IonGrid,
    IonButton,
    VForm: V.Form,
    EstablecimientoForm
    //VField: V.Field,
    //VErrorMessage: V.ErrorMessage
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    lote: { type: Object, default: () => ({}) },
    persona: { type: Object, default: () => ({}) },
    mapCoords: { type: Object, default: () => ({}) }
  },

  data: () => ({
    nombre: "",
    productor: null,
    establecimiento: null,
    regional: null,
    localidad: "",
    error1: "",
    hectareas: "",
    //error: "",
    tenencia: "",
    claseSuelo: "",
    calidadLote: "",
    coordenadas: {
      longitud: "",
      latitud: ""
    },
    regionales: [],
    establecimientos: [],
    productores: [],
    tenencias: ["Propio", "Arrendado", "Asesorado"],
    clasesSuelo: ["I", "II", "III", "IV", "V", "VI", "Sin Dato"],
    calidadesLote: ["Alto Potencial", "Medio Potencial", "Bajo Potencial"],
    icons: {
      add: addOutline
    },
    modalEstablecimiento: false
  }),

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"]),
    editing() {
      return !!this.lote._id;
    },
    isMapPath() {
      return this.$route.path.includes("/mapa");
    },
    isUserSameProd() {
      return this.persona._id === this.user._id;
    }
  },

  watch: {
    success(val) {
      if (val) {
        this.cleaner();
      }
    },
    lote() {
      this.cleaner();
      if (this.editing) {
        this.fillFields();
      }
    },
    async productor(val) {
      if (val) {
        await this.getEstablecimientos();
        if (val !== this.persona._id) {
          this.establecimiento = null;
        }
      } else {
        this.establecimientos = [];
        this.establecimiento = null;
      }
    },
    mapCoords: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.longitud && val.latitud) {
          this.coordenadas.longitud = val.longitud;
          this.coordenadas.latitud = val.latitud;
        }
      }
    },
    establecimiento(val) {
      if (val && val.regional && val.regional.nombre) {
        this.regional = val.regional._id;
      }
    }
  },

  async created() {
    await this.getRegionales();
    if (this.isAdmin) {
      await this.getProductores();
    }
    if (
      (!this.isAdmin && !this.productor) ||
      (this.isAdmin && this.productor)
    ) {
      await this.getEstablecimientos();
    }
    if (this.editing) {
      this.fillFields();
    } else {
      if (this.isAdmin) {
        this.productor = this.persona._id;
      }
    }
  },

  methods: {
    cleaner() {
      this.nombre = "";
      this.regional = null;
      this.establecimiento = null;
      this.productor = null;
      this.hectareas = "";
      this.coordenadas = { latitud: "", longitud: "" };
      this.localidad = "";
      this.tenencia = "";
      this.claseSuelo = "";
      this.calidadLote = "";
      //this.error = "";
    },

    async getRegionales() {
      const { data, error } = await Regionales.get({ sort: { nombre: 1 } });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo regionales.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      this.regionales = data;
    },

    async getProductores() {
      const { data, error } = await Persona.get({
        query: { deleted: false },
        sort: { apellido: 1, nombre: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Productores.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      this.productores = data;
    },

    async getEstablecimientos() {
      let pers = this.persona._id;
      if (this.isAdmin) {
        pers = null;
        pers = this.productor;
      }
      const query = { persona: pers, deleted: false };

      const { data, error } = await Establecimientos.get({
        query: query,
        sort: { nombre: 1 },
        populate: "regional"
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Establecimientos.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      this.establecimientos = data;
    },

    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    async submit() {
      this.$emit("update:success", false);
      let error = "";
      if (this.isAdmin && !this.productor) {
        error += "<li>Seleccione un <b>Productor</b>." + `<br/></li>`;
      }

      if (!this.establecimiento) {
        error += "<li>Seleccione un <b>Establecimiento</b>." + `<br/></li>`;
      }

      if (!this.nombre) {
        error += "<li>Complete el campo <b>Denominación</b>." + `<br/></li>`;
      }

      if (this.coordenadas.latitud.length && this.coordenadas.longitud.length) {
        if (isNaN(this.coordenadas.latitud)) {
          error += "<li>El campo <b>Latitud</b> es numérico." + `<br/></li>`;
        }
        if (isNaN(this.coordenadas.longitud)) {
          error += "<li>El campo <b>Longitud</b> es numérico." + `<br/></li>`;
        }
        if (
          !isLatLong(this.coordenadas.latitud + "," + this.coordenadas.longitud)
        ) {
          error += "<li>Las coordenadas no son válidas." + `<br/></li>`;
        }
      }

      if (!this.hectareas) {
        error += "<li>Complete el campo <b>Superficie</b>." + `<br/></li>`;
      } else if (isNaN(this.hectareas)) {
        error += "<li>El campo <b>Superficie</b> es numérico." + `<br/></li>`;
      } else if (this.hectareas < 0) {
        error +=
          "<li>El campo <b>Superficie</b> debe ser un número positivo." +
          `<br/></li>`;
      }

      if (!this.localidad) {
        error += "<li>Complete el campo <b>Localidad</b>." + `<br/></li>`;
      }
      if (!error) {
        this.$emit("submit", {
          lote: {
            _id: this.lote._id,
            nombre: this.nombre,
            establecimiento: this.establecimiento,
            localidad: this.localidad,
            regional: this.regional,
            hectareas: this.hectareas,
            coordenadas: this.coordenadas,
            persona: this.isAdmin ? this.productor : this.persona,
            tenencia: this.tenencia ? this.tenencia : undefined,
            claseSuelo: this.claseSuelo ? this.claseSuelo : undefined,
            calidadLote: this.calidadLote ? this.calidadLote : undefined
          }
        });
      } else {
        const alert = await alertController.create({
          header: "Error al crear el lote",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    async submitEstablecimiento({ establecimiento }) {
      this.error1 = "";
      const { data, error } = await Establecimientos.save(establecimiento);
      if (error) {
        this.error1 = error;
      } else {
        const alert = await alertController.create({
          header: "Establecimientos",
          message: "Establecimiento Creado",
          buttons: [
            {
              text: "Aceptar",
              handler: async () => {
                await this.getEstablecimientos();
                this.establecimiento = data;
                this.modalEstablecimiento = false;
              }
            }
          ]
        });
        this.modalEdit = false;
        alert.present();
      }
    },

    goToMapa() {
      this.cleaner();
      this.$emit("goToMapa");
    },

    fillFields() {
      this.nombre = this.lote.nombre;
      this.coordenadas.latitud = this.lote.coordenadas.latitud;
      this.coordenadas.longitud = this.lote.coordenadas.longitud;
      this.hectareas = this.lote.hectareas;
      this.regional = this.lote.regional;
      this.establecimiento = this.lote.establecimiento;
      this.localidad = this.lote.localidad;
      this.tenencia = this.lote.tenencia || "";
      this.claseSuelo = this.lote.claseSuelo || "";
      this.calidadLote = this.lote.calidadLote || "";
      if (this.isAdmin) {
        this.productor = this.lote.persona;
      }
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

.negrita {
  color: black;
  font-size: large;
  margin-left: 4px;
  font-weight: bold;
}

.label {
  font-size: medium;
}

.margen5px {
  margin-left: 5px;
}

ion-item {
  --pading-start: 8px;
}

ion-content {
  --background: transparent;
}
</style>
