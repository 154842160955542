<template>
  <ion-content :scroll-events="true">
    <ion-card>
      <ion-card-header class="ion-margin-top">
        <ion-card-title size="4" class="margen5px negrita">
          {{ !editing ? "Crear Establecimiento" : "Editar Establecimiento" }}
        </ion-card-title>
        <ion-row class="ion-margin-top">
          <ion-row class="ion-justify-content-left">
            <ion-label
              class="ion-align-self-center ion-margin-top label margen5px"
            >
              {{ "Productor: " }}
            </ion-label>
            <ion-text
              class="ion-align-self-center ion-margin-top ion-text-capitalize negrita"
            >
              {{ nombrePersona }}
            </ion-text>
          </ion-row>
        </ion-row>
      </ion-card-header>
      <ion-card-content>
        <v-form @submit="submit">
          <ion-item>
            <ion-label position="floating">Establecimiento</ion-label>
            <ion-input
              v-model="nombre"
              autocapitalize="words"
              type="text"
              inputmode="text"
              autocomplete="off"
              required
            ></ion-input>
          </ion-item>
          <ion-item class="ion-margin-top">
            <ion-label position="floating" class="ion-margin-top">
              Regional
            </ion-label>
            <ion-select
              :value="regional"
              @ionChange="regional = $event.target.value"
            >
              <ion-select-option
                v-for="reg in regionales"
                :value="reg._id"
                :key="reg._id"
              >
                {{ reg.nombre }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Localidad</ion-label>
            <ion-input
              v-model="localidad"
              autocapitalize="Words"
              type="text"
              inputmode="text"
              autocomplete="off"
              required
            ></ion-input>
          </ion-item>
          <ion-item v-show="error">
            <ion-text color="danger">
              <h4>{{ error1 }}</h4>
            </ion-text>
          </ion-item>
          <ion-row class="ion-align-items-center">
            <ion-col>
              <ion-button
                fill="clear"
                size="large"
                color="danger"
                @click="$emit('delete')"
              >
                <ion-icon :md="icons.trash" :ios="icons.trash"></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button
                fill="outline"
                class="ion-margin-top"
                color="medium"
                @click="cancel"
              >
                Cancelar
              </ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button class="ion-float-right ion-margin-top" type="submit">
                Aceptar
              </ion-button>
            </ion-col>
          </ion-row>
        </v-form>
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import * as V from "vee-validate/dist/vee-validate";
import {
  IonInput,
  IonLabel,
  IonText,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonCardHeader,
  IonItem,
  IonCard,
  IonCardTitle,
  IonContent,
  IonRow,
  IonCardContent,
  IonCol,
  alertController,
  IonButton
} from "@ionic/vue";
import { Regionales } from "@/api";
import { trashOutline } from "ionicons/icons";
import { mapState } from "vuex";

export default {
  components: {
    IonInput,
    IonText,
    IonIcon,
    IonSelect,
    IonCardHeader,
    IonSelectOption,
    IonLabel,
    IonItem,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonContent,
    IonRow,
    IonCol,
    IonButton,
    VForm: V.Form
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    establecimiento: { type: Object, default: () => ({}) },
    persona: { type: Object, default: () => ({}) }
  },

  data: () => ({
    nombre: "",
    regional: null,
    localidad: "",
    error1: "",
    nombrePersona: "",
    regionales: [],
    icons: {
      trash: trashOutline
    }
  }),

  computed: {
    ...mapState(["user"]),
    editing() {
      return !!this.establecimiento._id;
    }
  },

  watch: {
    success(val) {
      if (val) {
        this.cleaner();
      }
    },
    establecimiento() {
      this.cleaner();
      if (this.editing) {
        this.fillFields();
      }
    }
  },

  async created() {
    await this.getRegionales();
    if (this.editing) {
      this.fillFields();
    }
  },

  methods: {
    cleaner() {
      this.nombre = "";
      this.regional = null;
      this.localidad = "";
      this.nombrePersona = "";
      //this.error = "";
    },

    async getRegionales() {
      const { data, error } = await Regionales.get({ sort: { nombre: 1 } });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo regionales.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      this.regionales = data;
    },

    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    async submit() {
      this.$emit("update:success", false);
      let error = "";
      if (!this.nombre) {
        error += "<li>Complete el campo <b>Establecimiento</b>." + `<br/></li>`;
      }

      if (!this.regional) {
        error += "<li>Seleccione una <b>Regional</b>." + `<br/></li>`;
      }

      if (!this.localidad) {
        error += "<li>Complete el campo <b>Localidad</b>." + `<br/></li>`;
      }
      if (!error) {
        this.$emit("submit", {
          establecimiento: {
            _id: this.establecimiento ? this.establecimiento._id : undefined,
            nombre: this.nombre,
            localidad: this.localidad,
            regional: this.regional,
            persona: this.persona
          }
        });
      } else {
        const alert = await alertController.create({
          header: "Error al crear el establecimiento",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    fillFields() {
      this.nombre = this.establecimiento.nombre;
      this.regional = this.establecimiento.regional;
      this.localidad = this.establecimiento.localidad;
      this.nombrePersona =
        this.persona.nombre + " " + this.persona.apellido || "";
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

.negrita {
  color: black;
  font-size: large;
  margin-left: 4px;
  font-weight: bold;
}

.label {
  font-size: medium;
}

.margen5px {
  margin-left: 5px;
}

ion-item {
  --pading-start: 8px;
}

ion-content {
  --background: transparent;
}
</style>
